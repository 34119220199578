import React, { Component } from 'react'
import { Container, Row, Col, ListGroup, Form, Button } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../../dataProvider'


class Orderdetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }

        this.categories = [
            "Animaux",
            "Art & Culture",
            "Avocat & Juridique",
            "Beauté",
            "Code Promo",
            "Croyance & Voyance",
            "Entreprise",
            "Fêtes & Cérémonies",
            "Finance & Banque",
            "Cuisine & Gastronomie",
            "Généraliste",
            "Écologie",
            "Hightech & Informatique",
            "Immo & Démenagement",
            "Jeux d'argent",
            "Loisir créatif",
            "Maison & Jardin",
            "Mode",
            "Parentalité",
            "Rencontre",
            "Sexe",
            "Retraite",
            "Santé",
            "Science",
            "Sport & Loisir",
            "Tabac",
            "Transport",
            "Travaux & Dépannage",
            "Voyage & Hôtellerie",
            "Non classé",
        ]

    }

    submit() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        this.setState({ loading: true }, () => {
            const error = {}
            if (!this.state.category)
                error.category = true
            if (!this.state.scoreYTG)
                error.scoreYTG = true
            if (!this.state.spam_score)
                error.spam_score = true
            if (!this.state.price)
                error.price = true
            if (Object.keys(error).length === 0) {
                dataProvider.update('Post', {
                    data: {
                        id,
                        category: this.state.category,
                        scoreYTG: this.state.scoreYTG,
                        spam_score: this.state.spam_score,
                        price: this.state.price,
                        score_NextLevel: this.state.score_NextLevel || 0,
                        // score_Linksgarden: this.state.score_Linksgarden || 0,
                        score_Discover: this.state.score_Discover || 0,
                        score_Amazon: this.state.score_Amazon || 0,
                        secret_state: "published"
                    }
                }).then(() => window.location = '/post-view?id=' + id)
            } else {
                this.setState({ error, loading: false })
            }
        })
    }

    submit2() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        this.setState({ loading: true }, () => {
            dataProvider.update('Post', {
                data: {
                    id,
                    secret_state: "draft"
                }
            }).then(() => window.location = '/draft-view?id=' + id)
        })
    }

    componentDidMount() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        dataProvider.getOne("TextValidated", { id }).then((res) => {
            this.setState({
                data: res.data
            })
        })
    }

    render() {
        if (!this.state.data)
            return <></>
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/texts">Validated</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Validated Details</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="font-weight-bold">Validated Details</h4>
                                {/* <Link className="btn btn-primary btn-sm" to="/order-new">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        Generate Invoice
                    </Link> */}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <Card>
                                <ListGroup as="ul" className="list-group-flush">
                                    <ListGroup.Item as="li" className="p-3">
                                        <h5 className="font-weight-bold pb-2">Validated Info</h5>
                                        <div className="table-responsive">
                                            <table className="table table-borderless mb-0">
                                                <tbody>
                                                    <tr className="white-space-no-wrap">
                                                        <td className="text-muted pl-0">
                                                            Title
                                                        </td>
                                                        <td>
                                                            {this.state.data.title}
                                                        </td>
                                                    </tr>
                                                    <tr className="white-space-no-wrap">
                                                        <td className="text-muted pl-0">
                                                            Request
                                                        </td>
                                                        <td>
                                                            {this.state.data.target_request}
                                                        </td>
                                                    </tr>
                                                    <tr className="white-space-no-wrap">
                                                        <td className="text-muted pl-0">
                                                            Editor
                                                        </td>
                                                        <td>
                                                            {this.state.data.editor}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <div style={{ padding: "10px" }}>
                                    <h6 style={{ marginBottom: "10px" }}>Brief</h6>
                                    <div dangerouslySetInnerHTML={{ __html: `<p>${this.state.data.brief.split('\n').join('<br>')}</p>` }} />
                                </div>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <div style={{ padding: "10px" }}>
                                    <h6 style={{ marginBottom: "10px" }}>Preview Image</h6>
                                    <img src={this.state.data.preview_image} />
                                </div>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <div style={{ padding: "10px" }}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.data.content }} />
                                </div>
                            </Card>
                            <Card>
                                <Card.Body>
                                    {/* <h5 className="font-weight-bold mb-3">Basic Information</h5> */}
                                    <Form className="row g-3">
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Category *</Form.Label>
                                            {/* <Form.Control type="text" className="form-control" id="Text1" placeholder="Enter Category" onChange={e => this.setState({ category: e.target.value })} style={this.state.error && this.state.error.category ? { border: "1px solid red" } : {}} /> */}
                                            <select className="form-control choicesjs" id="validationServer01" placeholder="Category2" onChange={e => this.setState({ category: e.target.value })} style={this.state.error && this.state.error.category ? { border: "1px solid red" } : {}}>
                                                <option value="" selected>Select Category</option>
                                                {
                                                    this.categories.map(e => <option value={e}>{e}</option>)
                                                }
                                            </select>

                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Score YTG *</Form.Label>
                                            <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Score YTG" onChange={e => this.setState({ scoreYTG: e.target.value })} style={this.state.error && this.state.error.scoreYTG ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Spam Score *</Form.Label>
                                            <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Spam Score" onChange={e => this.setState({ spam_score: e.target.value })} style={this.state.error && this.state.error.spam_score ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Price *</Form.Label>
                                            <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Price" onChange={e => this.setState({ price: e.target.value })} style={this.state.error && this.state.error.price ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Répond a un besoin sur NextLevel</Form.Label>
                                            <div className="form-group mb-0">
                                                <select className="form-control choicesjs" id="validationServer01" placeholder="Répond a un besoin sur NextLevel" onChange={(e) => this.setState({ score_NextLevel: e.target.value })} >
                                                    <option value={0}>False</option>
                                                    <option value={1}>True</option>
                                                </select>
                                            </div>
                                            {/* <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Score NextLevel" onChange={e => this.setState({ score_NextLevel: e.target.value })} style={this.state.error && this.state.error.score_NextLevel ? { border: "1px solid red" } : {}} /> */}
                                        </div>
                                        {/* <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Répond a un besoin sur Linksgarden</Form.Label>
                                            <div className="form-group mb-0">
                                                <select className="form-control choicesjs" id="validationServer01" placeholder="Répond a un besoin sur Linksgarden" onChange={(e) => this.setState({ score_Linksgarden: e.target.value })} >
                                                    <option value={0}>False</option>
                                                    <option value={1}>True</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Optimisé pour Discover</Form.Label>
                                            <div className="form-group mb-0">
                                                <select className="form-control choicesjs" id="validationServer01" placeholder="Optimisé pour Discover" onChange={(e) => this.setState({ score_Discover: e.target.value })} >
                                                    <option value={0}>False</option>
                                                    <option value={1}>True</option>
                                                </select>
                                            </div>
                                            {/* <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Score Discover" onChange={e => this.setState({ score_Discover: e.target.value })} style={this.state.error && this.state.error.score_Discover ? { border: "1px solid red" } : {}} /> */}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Adapté pour Amazon</Form.Label>
                                            <div className="form-group mb-0">
                                                <select className="form-control choicesjs" id="validationServer01" placeholder="Adapté pour Amazon" onChange={(e) => this.setState({ score_Amazon: e.target.value })} >
                                                    <option value={0}>False</option>
                                                    <option value={1}>True</option>
                                                </select>
                                            </div>
                                            {/* <Form.Control type="number" className="form-control" id="Text1" placeholder="Enter Score Amazon" onChange={e => this.setState({ score_Amazon: e.target.value })} style={this.state.error && this.state.error.score_Amazon ? { border: "1px solid red" } : {}} /> */}
                                        </div>
                                    </Form>
                                </Card.Body>
                                <Card.Body>
                                    <div className="d-flex justify-content-end mt-3">
                                        {
                                            !this.state.loading && (
                                                <Button variant="btn btn-primary" style={{ marginRight: "20px" }} onClick={() => this.submit2()}>
                                                    Invalidate
                                                </Button>
                                            )
                                        }
                                        {
                                            !this.state.loading && (
                                                <Button variant="btn btn-primary" onClick={() => this.submit()}>
                                                    Validate
                                                </Button>
                                            )
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Orderdetails;