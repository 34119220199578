
const initstate = {
    cart: 0,
    changed: 0,
    sidebarToggle: 0
}

export const setCallback = (callback) => {
    let previous = initstate.changed
    setInterval(() => {
        if (previous !== initstate.changed) {
            callback(initstate)
        }
        previous = initstate.changed
    }, 100)
}

export const getState = () => {
    return initstate
}

export const setCart = (cart) => {
    initstate.cart = cart
    initstate.changed++
}

export const setSidebarToggle = (sidebarToggle) => {
    initstate.sidebarToggle = sidebarToggle
    initstate.changed++
}

