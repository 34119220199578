import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, ProgressBar } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../../dataProvider'

const csvtojson = require("csvtojson")
const { convertArrayToCSV } = require('convert-array-to-csv');


class Productadd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            selectedFile: null,
            step: 1,
        }
    }

    promiseCreate(json) {
        return new Promise(resolve => {
            dataProvider.create('TextVirgin', {
                data: {
                    title: json.title,
                    target_request: json.target_request,
                    brief: json.brief,
                }
            }).then((data) => resolve(data))
        })
    }

    submit() {
        console.log(this.state.selectedFile);
        const reader = new FileReader()
        reader.onload = (e) => {
            csvtojson().fromString(e.target.result).then((json) => {
                console.log(json)
                this.setState({ step: 2, json }, async () => {
                    const a = (per) => new Promise(resolve => this.setState({ per }, () => resolve()))
                    for (let i = 0; i < this.state.json.length; i++) {
                        this.state.json[i].result = "Skipped"
                        try {
                            const result = await this.promiseCreate(this.state.json[i])
                            this.state.json[i].result = result.data.id ? 'Uploaded' : 'Not uploaded'
                        } catch (e) {
                            console.log(e)
                            this.state.json[i].result = "Server Error"
                        }
                        await a(Math.floor(100 * (i + 1) / this.state.json.length))
                    }
                    this.setState({ step: 3 })
                })
            })
        };
        reader.readAsText(this.state.selectedFile)
    }

    downloadSamples() {
        const csv = 'title,target_request,brief\nLes dauphins volent-ils ?,dauphins,ceci est un test'
        const csvFile = 'texts.csv'
        const url = window.URL.createObjectURL(
            new Blob([csv]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            csvFile
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    downloadResult() {
        const csv = convertArrayToCSV(this.state.json)
        const resultFile = 'texts-results.csv'
        const url = window.URL.createObjectURL(
            new Blob([csv]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            resultFile
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/texts">Texts</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Imports Texts</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/product" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">Imports Texts</h4>
                        </Col>
                        <Col lg="12">
                            {
                                this.state.step === 1 && (
                                    <Card>
                                        <Card.Body>
                                            {/* <h5 className="font-weight-bold mb-3">Basic Information</h5> */}
                                            <Form className="row g-3">
                                                <div className="col-md-6 mb-3">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="inputGroupFile04"
                                                            accept='.csv'
                                                            value={null}
                                                            onChange={(e) => this.setState({ selectedFile: e.target.files[0] })}
                                                        />
                                                        <label className="custom-file-label" htmlFor="inputGroupFile04">
                                                            Choose file
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.selectedFile && (
                                                            <div style={{ display: "flex", marginTop: "10px" }}>
                                                                <h6>{this.state.selectedFile.name}</h6>
                                                                <div style={{ cursor: "pointer" }} onClick={() => this.setState({ selectedFile: null })}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    <div>

                                                    </div>
                                                </div>
                                            </Form>
                                        </Card.Body>
                                        <Card.Body>
                                            <div className="d-flex justify-content-end mt-3">
                                                <Button variant="btn btn-primary" style={{ marginRight: "20px" }} onClick={() => this.downloadSamples()}>
                                                    Download Samples
                                                </Button>
                                                {
                                                    !this.state.loading && (
                                                        <Button variant="btn btn-primary" disabled={!this.state.selectedFile} onClick={() => this.submit()}>
                                                            Import Texts
                                                        </Button>
                                                    )
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            }
                            {
                                this.state.step === 2 && (
                                    <Card>
                                        <Card.Body>
                                            <ProgressBar className="mb-3" now={this.state.per} label={`${this.state.per}%`} style={{}} />
                                        </Card.Body>
                                    </Card>
                                )
                            }
                            {
                                this.state.step === 3 && (
                                    <Card>
                                        <Card.Body>
                                            <div className="d-flex justify-content-end mt-3">
                                                <Button variant="btn btn-primary" style={{ marginRight: "20px" }} onClick={() => this.downloadResult()}>
                                                    Download result
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            }

                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Productadd;