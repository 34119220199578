import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tab, Nav, Tooltip } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'

import dataProvider from '../../../../dataProvider'


const formatDate = (d) => {
    d = d.split('T')[0]
    const arr = d.split('-')
    return `${arr[2]}/${arr[1]}/${arr[0]}`
}

class Product extends Component {

    constructor(props) {
        super(props)

        this.state = {
            limit: 1000,
            page: 1,
            field: null,
            order: null,
            filterLogin: '',
            filterEmail: '',
            p_limit: 1000,
            p_page: 1,
            p_field: null,
            p_order: null,
            p_filterLogin: '',
            p_filterEmail: '',
        }
    }

    componentDidMount() {
        this.call()
    }

    call() {
        dataProvider.getList('User', { pagination: { page: this.state.page, perPage: this.state.limit }, sort: { field: this.state.field, order: this.state.order }, filter: { login: this.state.filterLogin, email: this.state.filterEmail } })
            .then(response => this.setState({ data: response.data, total: response.total }))
        dataProvider.getList('Preuser', { pagination: { page: this.state.p_page, perPage: this.state.p_limit }, sort: { field: this.state.p_field, order: this.state.p_order }, filter: { login: this.state.p_filterLogin, email: this.state.p_filterEmail, converted: 0 } })
            .then(response => this.setState({ p_data: response.data, p_total: response.total }))
    }

    render() {
        if (!this.state.data || !this.state.p_data)
            return <></>
        const up = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
            </svg>
        )
        const down = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
            </svg>
        )
        const sort = (source) => () => this.state.field !== source ? this.setState({ field: source, order: 'desc' }, () => this.call()) : this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () => this.call())
        const p_sort = (source) => () => this.state.p_field !== source ? this.setState({ p_field: source, p_order: 'desc' }, () => this.call()) : this.setState({ p_order: this.state.p_order === 'desc' ? 'asc' : 'desc' }, () => this.call())

        return (
            <>
                <Container fluid>
                    <Row>
                        <Card.Body className="p-0">
                            <Tab.Container defaultActiveKey="first">
                                <Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">
                                    <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="first" bsPrefix="font-weight-bold text-uppercase px-5 py-2 " >Users</Nav.Link></Nav.Item>
                                    <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="second" bsPrefix="font-weight-bold text-uppercase px-5 py-2" >Users Not Activated</Nav.Link></Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first" className="fade p-3">
                                        <Col lg="12">
                                            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4 className="font-weight-bold">Users</h4>
                                                </div>
                                                <div className="create-workform">
                                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                        <div className="modal-product-search d-flex">
                                                            <Form className="mr-3 position-relative">
                                                                <div className="form-group mb-0">
                                                                    <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Login" onChange={(e) => this.setState({ filterLogin: e.target.value }, () => this.call())} />
                                                                    {/* <Link className="search-link" to="#">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                        </svg>
                                                    </Link> */}
                                                                </div>
                                                            </Form>
                                                            <Form className="mr-3 position-relative">
                                                                <div className="form-group mb-0">
                                                                    <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Email" onChange={(e) => this.setState({ filterEmail: e.target.value }, () => this.call())} />
                                                                </div>
                                                            </Form>
                                                            {/* <Link to="/user-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                </svg>
                                                Add User
                                            </Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col lg="12">
                                                    <Card className="card-block card-stretch">
                                                        <Card.Body className="p-0">
                                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                                <h5 className="font-weight-bold">Users List</h5>
                                                                {/* <Button variant="btn btn-secondary btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                        Export
                                    </Button> */}
                                                            </div>
                                                            <div className="table-responsive">
                                                                <table className="table data-table mb-0">
                                                                    <thead className="table-color-heading">
                                                                        <tr className="text-light">
                                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("login")}>
                                                                                <label className="text-muted m-0" style={{ cursor: "pointer" }} >Login</label>
                                                                                {' '}
                                                                                {this.state.field === "login" && this.state.order === 'desc' && (down)}
                                                                                {this.state.field === "login" && this.state.order === 'asc' && (up)}
                                                                            </th>
                                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("email")}>
                                                                                <label className="text-muted mb-0" style={{ cursor: "pointer" }} >Email</label>
                                                                                {' '}
                                                                                {this.state.field === "email" && this.state.order === 'desc' && (down)}
                                                                                {this.state.field === "email" && this.state.order === 'asc' && (up)}
                                                                            </th>
                                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("credits")}>
                                                                                <label className="text-muted mb-0" style={{ cursor: "pointer" }} >Credits</label>
                                                                                {' '}
                                                                                {this.state.field === "credits" && this.state.order === 'desc' && (down)}
                                                                                {this.state.field === "credits" && this.state.order === 'asc' && (up)}
                                                                            </th>
                                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("createdAt")}>
                                                                                <label className="text-muted m-0" style={{ cursor: "pointer" }} >Date</label>
                                                                                {' '}
                                                                                {this.state.field === "createdAt" && this.state.order === 'desc' && (down)}
                                                                                {this.state.field === "createdAt" && this.state.order === 'asc' && (up)}
                                                                            </th>
                                                                            <th scope="col">
                                                                            </th>
                                                                            <th scope="col">
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.data.map((item) => (
                                                                                <tr key={item.Price} className="white-space-no-wrap">
                                                                                    <td>
                                                                                        {item.login}
                                                                                    </td>
                                                                                    <td>{item.email}</td>
                                                                                    <td>
                                                                                        {item.credits}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.createdAt}
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="d-flex justify-content-end align-items-center">
                                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                                                <Link to={"/user-edit?id=" + item.id} className="">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 20 20" fill="currentColor">
                                                                                                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                                                                    </svg>
                                                                                                </Link>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="d-flex justify-content-end align-items-center">
                                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                                                                                <Link to={"/user-view?id=" + item.id} className="">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                                                                    </svg>
                                                                                                </Link>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))

                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                {/* <div>
                                                    {
                                                        this.state.page > 1 && (
                                                            <>
                                                                <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ page: this.state.page - 1 }, () => this.call())}>
                                                                    {this.state.page - 1}
                                                                </span>
                                                                {' '}
                                                                {'-'}
                                                                {' '}
                                                            </>
                                                        )
                                                    }
                                                    <span>
                                                        {this.state.page}
                                                    </span>
                                                    {
                                                        this.state.total > this.state.page * this.state.limit && (
                                                            <>
                                                                {' '}
                                                                {'-'}
                                                                {' '}
                                                                <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ page: this.state.page + 1 }, () => this.call())}>
                                                                    {this.state.page + 1}
                                                                </span>
                                                            </>

                                                        )
                                                    }
                                                    {' '}
                                                    <span>
                                                        ({this.state.total % this.state.limit === 0 ? this.state.total / this.state.limit : Math.floor(this.state.total / this.state.limit) + 1})
                                                    </span>
                                                </div> */}
                                            </Row>
                                        </Col>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second" className="fade p-3">
                                        <Col lg="12">
                                            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4 className="font-weight-bold">Users Not Activated</h4>
                                                </div>
                                                <div className="create-workform">
                                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                        <div className="modal-product-search d-flex">
                                                            <Form className="mr-3 position-relative">
                                                                <div className="form-group mb-0">
                                                                    <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Login" onChange={(e) => this.setState({ p_filterLogin: e.target.value }, () => this.call())} />
                                                                    {/* <Link className="search-link" to="#">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                        </svg>
                                                    </Link> */}
                                                                </div>
                                                            </Form>
                                                            <Form className="mr-3 position-relative">
                                                                <div className="form-group mb-0">
                                                                    <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Email" onChange={(e) => this.setState({ p_filterEmail: e.target.value }, () => this.call())} />
                                                                </div>
                                                            </Form>
                                                            {/* <Link to="/user-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                </svg>
                                                Add User
                                            </Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col lg="12">
                                                    <Card className="card-block card-stretch">
                                                        <Card.Body className="p-0">
                                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                                <h5 className="font-weight-bold">Users List</h5>
                                                                {/* <Button variant="btn btn-secondary btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                        Export
                                    </Button> */}
                                                            </div>
                                                            <div className="table-responsive">
                                                                <table className="table data-table mb-0">
                                                                    <thead className="table-color-heading">
                                                                        <tr className="text-light">
                                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={p_sort("login")}>
                                                                                <label className="text-muted m-0" style={{ cursor: "pointer" }} >Login</label>
                                                                                {' '}
                                                                                {this.state.p_field === "login" && this.state.p_order === 'desc' && (down)}
                                                                                {this.state.p_field === "login" && this.state.p_order === 'asc' && (up)}
                                                                            </th>
                                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={p_sort("email")}>
                                                                                <label className="text-muted mb-0" style={{ cursor: "pointer" }} >Email</label>
                                                                                {' '}
                                                                                {this.state.p_field === "email" && this.state.p_order === 'desc' && (down)}
                                                                                {this.state.p_field === "email" && this.state.p_order === 'asc' && (up)}
                                                                            </th>
                                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={p_sort("createdAt")}>
                                                                                <label className="text-muted m-0" style={{ cursor: "pointer" }} >Date</label>
                                                                                {' '}
                                                                                {this.state.p_field === "createdAt" && this.state.p_order === 'desc' && (down)}
                                                                                {this.state.p_field === "createdAt" && this.state.p_order === 'asc' && (up)}
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.p_data.map((item) => (
                                                                                <tr key={item.Price} className="white-space-no-wrap">
                                                                                    <td>
                                                                                        {item.login}
                                                                                    </td>
                                                                                    <td>{item.email}</td>
                                                                                    <td>
                                                                                        {item.createdAt}
                                                                                    </td>
                                                                                </tr>
                                                                            ))

                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                {/* <div>
                                                    {
                                                        this.state.p_page > 1 && (
                                                            <>
                                                                <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ page: this.state.p_page - 1 }, () => this.call())}>
                                                                    {this.state.p_page - 1}
                                                                </span>
                                                                {' '}
                                                                {'-'}
                                                                {' '}
                                                            </>
                                                        )
                                                    }
                                                    <span>
                                                        {this.state.p_page}
                                                    </span>
                                                    {
                                                        this.state.p_total > this.state.p_page * this.state.p_limit && (
                                                            <>
                                                                {' '}
                                                                {'-'}
                                                                {' '}
                                                                <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ page: this.state.p_page + 1 }, () => this.call())}>
                                                                    {this.state.p_page + 1}
                                                                </span>
                                                            </>

                                                        )
                                                    }
                                                    {' '}
                                                    <span>
                                                        ({this.state.p_total % this.state.p_limit === 0 ? this.state.p_total / this.state.p_limit : Math.floor(this.state.p_total / this.state.p_limit) + 1})
                                                    </span>
                                                </div> */}
                                            </Row>
                                        </Col>

                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>

                    </Row>
                </Container>
            </>
        )
    }
}

export default Product;
