import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'

import dataProvider from '../../../../dataProvider'


const formatDate = (d) => {
    d = d.split('T')[0]
    const arr = d.split('-')
    return `${arr[2]}/${arr[1]}/${arr[0]}`
}

class Editor extends Component {

    constructor(props) {
        super(props)

        this.state = {
            limit: 1000,
            page: 1,
            field: null,
            order: null,
            filterLogin: '',
        }
    }

    componentDidMount() {
        this.call()
    }

    call() {
        dataProvider.getList('Editor', { pagination: { page: this.state.page, perPage: this.state.limit }, sort: { field: this.state.field, order: this.state.order }, filter: { login_part: this.state.filterLogin } })
            .then(response => this.setState({ data: response.data, total: response.total }))
    }

    delete(id) {
        this.setState({ loading: true }, () => {
            dataProvider.delete('Editor', {
                id
            }).then(() => window.location = '/editors')
        })
    }

    render() {
        if (!this.state.data)
            return <></>
        const up = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
            </svg>
        )
        const down = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
            </svg>
        )
        const sort = (source) => () => this.state.field !== source ? this.setState({ field: source, order: 'desc' }, () => this.call()) : this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () => this.call())

        return (
            <>
                <Container fluid>
                    <Row>

                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="font-weight-bold">Editors</h4>
                                </div>
                                <div className="create-workform">
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="modal-editor-search d-flex">
                                            <Form className="mr-3 position-relative">
                                                <div className="form-group mb-0">
                                                    <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search Editor" onChange={(e) => this.setState({ filterLogin: e.target.value }, () => this.call())} />
                                                </div>
                                            </Form>
                                            <Link to="/editor-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                </svg>
                                                Add Editor
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <Card className="card-block card-stretch">
                                        <Card.Body className="p-0">
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <h5 className="font-weight-bold">Editors List</h5>
                                                {/* <Button variant="btn btn-secondary btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                        Export
                                    </Button> */}
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="text-light">
                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("login")}>
                                                                <label className="text-muted m-0" style={{ cursor: "pointer" }} >Login</label>
                                                                {' '}
                                                                {this.state.field === "login" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "login" && this.state.order === 'asc' && (up)}
                                                                {/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="Search Editor">  */}
                                                            </th>

                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("createdAt")}>
                                                                <label className="text-muted mb-0" style={{ cursor: "pointer" }} >Date</label>
                                                                {' '}
                                                                {this.state.field === "createdAt" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "createdAt" && this.state.order === 'asc' && (up)}
                                                            </th>
                                                            <th scope="col">
                                                            </th>
                                                            <th scope="col">
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.data.map((item) => (
                                                                <tr key={item.Price} className="white-space-no-wrap">
                                                                    <td>{item.login}</td>
                                                                    <td>
                                                                        {formatDate(item.createdAt)}
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex justify-content-end align-items-center">
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                                                                <Link to={"/editor-view?id=" + item.id} className="">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                                                    </svg>
                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex justify-content-end align-items-center">
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                                                                <Link className="" onClick={() => this.delete(item.id)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                    </svg>

                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))

                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                {/* <div>
                                    {
                                        this.state.page > 1 && (
                                            <>
                                                <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ page: this.state.page - 1 }, () => this.call())}>
                                                    {this.state.page - 1}
                                                </span>
                                                {' '}
                                                {'-'}
                                                {' '}
                                            </>
                                        )
                                    }
                                    <span>
                                        {this.state.page}
                                    </span>
                                    {
                                        this.state.total > this.state.page * this.state.limit && (
                                            <>
                                                {' '}
                                                {'-'}
                                                {' '}
                                                <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.setState({ page: this.state.page + 1 }, () => this.call())}>
                                                    {this.state.page + 1}
                                                </span>
                                            </>

                                        )
                                    }
                                    {' '}
                                    <span>
                                        ({this.state.total % this.state.limit === 0 ? this.state.total / this.state.limit : Math.floor(this.state.total / this.state.limit) + 1})
                                    </span>
                                </div> */}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Editor;
