import React, { Component } from 'react'
import { Container, Row, Col, ListGroup } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../../dataProvider'


class Orderdetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        dataProvider.getOne("Editor", { id }).then((res) => {
            this.setState({
                data: res.data
            })
        })
    }

    render() {
        if (!this.state.data)
            return <></>
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/editors">Editor</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Editor Details</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="font-weight-bold">Editor Details</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4">
                            <Card>
                                <ListGroup as="ul" className="list-group-flush">
                                    <ListGroup.Item as="li" className="p-3">
                                        <h5 className="font-weight-bold pb-2">Editor Info</h5>
                                        <div className="table-responsive">
                                            <table className="table table-borderless mb-0">
                                                <tbody>
                                                    <tr className="white-space-no-wrap">
                                                        <td className="text-muted pl-0">
                                                            Login
                                                        </td>
                                                        <td>
                                                            {this.state.data.login}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col lg="8">
                            <Card>
                                <Card.Body className="p-0">
                                    <div className="d-flex justify-content-between align-items-center p-3">
                                        <h5>Posts List</h5>
                                    </div>
                                    {
                                        this.state.data.posts && (
                                            <div className="table-responsive">
                                                <table className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="text-muted">
                                                            <th scope="col">Title</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.data.posts.map(elem => (
                                                                <tr>
                                                                    <td>{elem.title}</td>
                                                                    <td>{elem.secret_state}</td>
                                                                    {/* <td>
                                                                        {
                                                                            !this.state.data.websites.includes(elem.id) && (
                                                                                <div className="d-flex justify-content-end align-items-center">
                                                                                    <Link className="" onClick={() => dataProvider.customs.linkPost(this.state.data.id, elem.id).then(() => window.location.reload())}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                                                        </svg>
                                                                                    </Link>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </td> */}
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    }
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </>
        )
    }
}

export default Orderdetails;