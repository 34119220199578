import React, { Component } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../../dataProvider'


class Productadd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    submit() {
        this.setState({ loading: true }, () => {
            const error = {}
            if (!this.state.login)
                error.title = true
            if (!this.state.password)
                error.password = true
            if (Object.keys(error).length === 0) {
                dataProvider.create('Editor', {
                    data: {
                        login: this.state.login,
                        password: this.state.password,
                    }
                }).then(() => window.location = '/editors')
            } else {
                this.setState({ error, loading: false })
            }
        })
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/editor">Editors</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Add Editor</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/product" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">New Editor</h4>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    {/* <h5 className="font-weight-bold mb-3">Basic Information</h5> */}
                                    <Form className="row g-3">
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Login *</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text1" placeholder="Enter Login" onChange={e => this.setState({ login: e.target.value })} style={this.state.error && this.state.error.login ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Password *</Form.Label>
                                            <Form.Control type="password" className="form-control" id="Text1" placeholder="Enter Login" onChange={e => this.setState({ password: e.target.value })} style={this.state.error && this.state.error.password ? { border: "1px solid red" } : {}} />
                                        </div>
                                    </Form>
                                </Card.Body>
                                <Card.Body>
                                    <div className="d-flex justify-content-end mt-3">
                                        {
                                            !this.state.loading && (
                                                <Button variant="btn btn-primary" onClick={() => this.submit()}>
                                                    Create Editor
                                                </Button>
                                            )
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Productadd;