import React, { Component } from 'react'
import { Container, Row, Col, Button, ListGroup, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import Chart from "react-apexcharts";
// img
import user1 from '../../../assets/images/user/1.jpg'
import dataProvider from '../../../dataProvider'


const chart1 = {
    series: [{
        data: [91, 82, 90, 88, 105, 99]
    }],
    options: {
        chart: {
            //   height: 265,
            //   type: 'bar',
            toolbar: {
                show: false,
            },
            events: {
                click: function (chart, w, e) {
                    // console.log(chart, w, e)
                }
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '35%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        legend: {
            show: false
        },
        yaxis: {
            labels: {
                offsetY: 0,
                minWidth: 20,
                maxWidth: 20
            },
        },
        xaxis: {
            categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'June',
            ],
            labels: {
                minHeight: 22,
                maxHeight: 22,
                style: {
                    fontSize: '12px'
                }
            }
        }
    }

}

class Orderdetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        dataProvider.getOne("PostOrder", { id }).then((res) => {
            this.setState({
                data: res.data
            })
        })
        dataProvider.getList('Website', { pagination: { page: 1, perPage: 10000 }, sort: { field: null, order: null }, filter: {} })
            .then(response => this.setState({ websites: response.data }))
    }

    render() {
        if (!this.state.data)
            return <></>
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/order">Posts</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Post View</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/order" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">Post View</h4>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="4">
                            <Card>
                                <ListGroup as="ul" className="list-group-flush">
                                    <ListGroup.Item as="li">
                                        <table className="table table-borderless mb-0">
                                            <tbody>
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">Title</p>
                                                    </td>
                                                    <td>
                                                        <h6>{this.state.data.title}</h6>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">Category</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.category}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">Count Word</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.count_words}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">Spam Score</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.spam_score}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">Score YTG</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.scoreYTG}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col lg="8">
                            <Card>
                                <Card.Body className="p-0">
                                    <Tab.Container defaultActiveKey="first">
                                        <Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">
                                            <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="first" bsPrefix="font-weight-bold text-uppercase px-5 py-2 " >Websites</Nav.Link></Nav.Item>
                                            <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="second" bsPrefix="font-weight-bold text-uppercase px-5 py-2" >Content</Nav.Link></Nav.Item>
                                        </Nav>

                                        <Tab.Content>
                                            <Tab.Pane eventKey="first" className=" fade show  ">
                                                <div className="d-flex justify-content-between align-items-center p-3">
                                                    <h5>Website List</h5>
                                                </div>
                                                {
                                                    this.state.websites && (
                                                        <div className="table-responsive">
                                                            <table className="table data-table mb-0">
                                                                <thead className="table-color-heading">
                                                                    <tr className="text-muted">
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col">URL</th>
                                                                        <th scope="col">Status</th>
                                                                        <th scope="col"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.websites.map(elem => (
                                                                            <tr>
                                                                                <td>{elem.name}</td>
                                                                                <td><a href={elem.url}>{elem.url}</a></td>
                                                                                <td>
                                                                                    {
                                                                                        this.state.data.websites.includes(elem.id) ? (
                                                                                            <p className="mb-0 text-success d-flex justify-content-start align-items-center">
                                                                                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                                                    <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                                                                                                Link
                                                                                            </p>

                                                                                        ) : (
                                                                                            <p className="mb-0 text-danger d-flex justify-content-start align-items-center">
                                                                                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                                                    <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle></svg>Not link
                                                                                            </p>
                                                                                        )
                                                                                    }

                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        !this.state.data.websites.includes(elem.id) && (
                                                                                            <div className="d-flex justify-content-end align-items-center">
                                                                                                <Link className="" onClick={() => dataProvider.customs.linkPost(this.state.data.id, elem.id).then(() => window.location.reload())}>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                                                                    </svg>
                                                                                                </Link>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                }
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second" className="  fade p-3">
                                                <div dangerouslySetInnerHTML={{ __html: this.state.data.content }} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </Container >
            </>
        )
    }
}
export default Orderdetails;