
import { stringify } from 'query-string'
import authProvider from './authProvider'
import { loadStripe } from '@stripe/stripe-js';
import { REACT_APP_API_HOSTNAME } from './constants/constants'
import { REACT_APP_STRIPE_PUBLIC_KEY } from './constants/constants'

const API_URL = REACT_APP_API_HOSTNAME

console.log({ API_URL })

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);


const mapGet = {
    'User': 'superuser/users',
    'Preuser': 'superuser/preusers',
    'Post': 'superuser/posts',
    'Editor': 'superuser/editors',
    'TextVirgin': 'superuser/texts/virgin',
    'TextDraft': 'superuser/texts/draft',
    'TextValidated': 'superuser/texts/validated',
    'Partner': 'superuser/partners',
}

const mapPost = {
    'User': 'superuser/user',
    'Editor': 'superuser/editor',
    'Post': 'superuser/post',
    'TextVirgin': 'superuser/text/virgin',
    'Partner': 'superuser/partner',
}


const fetchJson = (url, options) => {
    return fetch(url, options).then(response => response.json())
}

const dataProvider = {
    getList: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('getList', { resource, params })
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
            limit: perPage,
            offset: (page - 1) * perPage,
            sort_field: field,
            sort_order: order,
            ...params.filter
        }
        const url = `${API_URL}/${mapGet[resource]}?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            console.log(json)
            // if (resource === 'User') {
            //     resolve({
            //         data: json.data,
            //         total: json.total
            //     })
            // }
            resolve({
                data: json.data,
                total: json.total
            })
        })
    }),
    csv: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('csv', { resource, params })
        const query = {
            ...params.filter
        }
        const url = `${API_URL}/${mapGet[resource]}/csv?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            resolve({
                csv: json.csv,
            })
        })
    }),
    getOne: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('getOne', { resource, params })
        const url = `${API_URL}/${mapGet[resource]}?${stringify({ id: params.id })}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            // json = JSON.parse(json.body)
            // if (resource === 'User') {
            //     resolve({
            //         data: json.data[0],
            //     })
            // }
            resolve({
                data: json.data[0],
            })
        })
    }),
    getMany: (resource, params) => new Promise(async (resolve) => {
        console.log('getMany', { resource, params })
        const result = []
        for (let i = 0; i < params.ids.length; i++) {
            const ret = await dataProvider.getOne(resource, { id: params.ids[i] })
            if (ret.data)
                result.push(ret.data)
        }
        console.log(result)
        resolve({ data: result })
    }),
    getManyReference: (resource, params) => new Promise(resolve => {
        console.log('getManyReference', { resource, params })
        resolve()
    }),
    create: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('create', { resource, params })
        if (resource === 'Professor') {
            // params.data.lastCrawlDate = new Date(params.data.lastCrawlDate).getTime()
        }
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        requestHeaders.set('Content-Type', 'application/json')
        const options = { headers: requestHeaders }
        options.method = 'POST'
        options.body = JSON.stringify(params.data)
        fetchJson(url, options).then(json => {
            resolve({ data: { ...params.data, id: json.id } })
        })
    }),
    update: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('update', { resource, params })
        delete params.data.updatedAt
        delete params.data.createdAt
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        requestHeaders.set('Content-Type', 'application/json')
        const options = { headers: requestHeaders }
        options.method = 'PUT'
        options.body = JSON.stringify(params.data)
        fetchJson(url, options).then(json => {
            resolve({ data: { ...params.data } })
        })
    }),
    updateMany: (resource, params) => new Promise(resolve => {
        console.log('updateMany', { resource, params })
        resolve()
    }),
    delete: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('delete', { resource, params })
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        requestHeaders.set('Content-Type', 'application/json')
        const options = { headers: requestHeaders }
        options.method = 'DELETE'
        options.body = JSON.stringify({ id: params.id })
        fetchJson(url, options).then(json => {
            resolve({ data: { ...params.id } })
        })
    }),
    deleteMany: (resource, params) => new Promise(async (resolve) => {
        console.log('deleteMany', { resource, params })
        for (let i = 0; i < params.ids.length; i++) {
            await dataProvider.delete(resource, { id: params.ids[i] })
        }
        resolve({ data: [...params.ids] })
    }),
    customs: {
        // dashboard: (start, end, website) => new Promise(async (resolve) => {
        //     await authProvider.checkAuth()
        //     const url = `${API_URL}/user/dashboard?${stringify({ start, end, website })}`
        //     const requestHeaders = new Headers()
        //     requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        //     fetchJson(url, { headers: requestHeaders }).then(json => {
        //         resolve({
        //             data: json.data,
        //         })
        //     })
        // }),


        resendActicationPartner: (id) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            requestHeaders.set('Content-Type', 'application/json')
            await fetch(`${API_URL}/superuser/partner/resend/activation`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({ id })
            });
            resolve()
        }),
        exchangePartner: (id) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            requestHeaders.set('Content-Type', 'application/json')
            await fetch(`${API_URL}/superuser/partner/exchange`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({ id })
            });
            resolve()
        }),
        changePassword: (password, old_password) => {
            const requestHeaders = new Headers()
            requestHeaders.set("Content-Type", "application/json")
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetch(API_URL + "/superuser/change-password", {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify({
                    password,
                    old_password
                })
            }).then(response =>
                response.text().then(text => ({
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: text,
                }))
            ).then(({ status, statusText, headers, body }) => {
                window.location.reload()
            });
        },
    }
}

export default dataProvider
