import React from 'react'
import { Container, Col, Row, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { connect } from "react-redux";
import { getDarkMode } from '../../../store/mode'

//img
import logo from '../../../assets/images/logo.png'
import darklogo from '../../../assets/images/logo-dark.png'



import { useHistory } from 'react-router-dom'
import { Component } from 'react';

import authProvider from '../../../authProvider';


function mapStateToProps(state) {
   return {
      darkMode: getDarkMode(state)
   };
}


class RecoverPassword extends Component {

   constructor(props) {
      super(props)
      this.state = {
         email: '',
         loading: false
      }
   }

   componentDidMount() {

   }


   render() {

      const props = this.props

      return (
         <>
            <section className="login-content">
               <Container className="h-100">
                  <Row className="align-items-center justify-content-center h-100">
                     <Col md="5">
                        <Card className="p-5">
                           <Card.Body>
                              <div className="auth-logo">
                                 <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} alt="logo" />
                                 <img src={darklogo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                              </div>
                              <h3 className="mb-3 text-center">Reset Password</h3>
                              <p className="text-center small text-secondary mb-3">You can reset your password here</p>
                              <Form>
                                 <Row>
                                    <Col lg="12">
                                       <Form.Group >
                                          <Form.Label className="text-secondary">Email</Form.Label>
                                          <Form.Control type="email" placeholder="Enter Email" onChange={(e) => this.setState({ email: e.target.value })} />
                                       </Form.Group >
                                    </Col>
                                 </Row>
                                 {
                                    !this.state.loading && (
                                       <Button type="submit" onClick={() => this.setState({ loading: true }, () => authProvider.forgottenPassword(this.state.email).then(() => window.location = '/auth/sign-in'))} variant="btn btn-primary btn-block">Reset Password</Button>
                                    )
                                 }
                              </Form>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
               </Container>
            </section>
         </>
      )
   }
}

export default connect(mapStateToProps)(RecoverPassword)