import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'

import dataProvider from '../../../dataProvider'

// img
import product1 from '../../../assets/images/products/1.jpg'
import product15 from '../../../assets/images/products/15.jpg'
import product2 from '../../../assets/images/products/2.jpg'
import product4 from '../../../assets/images/products/4.jpg'

import product8 from '../../../assets/images/products/8.jpg'

import product5 from '../../../assets/images/products/5.jpg'

import product7 from '../../../assets/images/products/7.jpg'
import product16 from '../../../assets/images/products/16.jpg'
import product6 from '../../../assets/images/products/6.jpg'
import product12 from '../../../assets/images/products/12.jpg'

const formatDate = (d) => {
    d = d.split('T')[0]
    const arr = d.split('-')
    return `${arr[2]}/${arr[1]}/${arr[0]}`
}

class Product extends Component {

    constructor(props) {
        super(props)

        this.state = {
            limit: 1000,
            page: 1,
            field: null,
            order: null,
            filterName: '',
            filterUrl: '',
        }
    }

    componentDidMount() {
        this.call()
    }

    call() {
        dataProvider.getList('User', { pagination: { page: this.state.page, perPage: this.state.limit }, sort: { field: this.state.field, order: this.state.order }, filter: { name: this.state.filterName, url: this.state.filterUrl } })
            .then(response => this.setState({ data: response.data }))
    }

    render() {
        if (!this.state.data)
            return <></>
        const up = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
            </svg>
        )
        const down = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
            </svg>
        )
        const sort = (source) => () => this.state.field !== source ? this.setState({ field: source, order: 'desc' }, () => this.call()) : this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () => this.call())

        return (
            <>
                <Container fluid>
                    <Row>

                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="font-weight-bold">User</h4>
                                </div>
                                <div className="create-workform">
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="modal-product-search d-flex">
                                            <Form className="mr-3 position-relative">
                                                <div className="form-group mb-0">
                                                    <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search User" onChange={(e) => this.setState({ filterName: e.target.value }, () => this.call())} />
                                                    {/* <Link className="search-link" to="#">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                        </svg>
                                                    </Link> */}
                                                </div>
                                            </Form>
                                            <Form className="mr-3 position-relative">
                                                <div className="form-group mb-0">
                                                    <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Url" onChange={(e) => this.setState({ filterUrl: e.target.value }, () => this.call())} />
                                                </div>
                                            </Form>
                                            <Link to="/user-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                </svg>
                                                Add User
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <Card className="card-block card-stretch">
                                        <Card.Body className="p-0">
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <h5 className="font-weight-bold">Users List</h5>
                                                {/* <Button variant="btn btn-secondary btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                        Export
                                    </Button> */}
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="text-light">
                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("name")}>
                                                                <label className="text-muted m-0" style={{ cursor: "pointer" }} >Name</label>
                                                                {' '}
                                                                {this.state.field === "name" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "name" && this.state.order === 'asc' && (up)}
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" >Url</label>
                                                                {/* <select className="custom-select custom-select-sm mt-2 choicesjs" id="validationServer01">
                                                        <option selected>Select Category</option>
                                                        <option value="1">Tablet</option>
                                                        <option value="2">Speaker</option>
                                                        <option value="3">Pendrive</option>
                                                        <option value="4">Mobile</option>
                                                        <option value="5">Laptop</option>
                                                        <option value="6">Headset</option>
                                                    </select>  */}
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" >State</label>
                                                            </th>
                                                            {/* <th scope="col">
                                                            <label className="text-muted mb-0" >Inventory</label>
                                                             <select className="custom-select custom-select-sm mt-2" id="validationServer02">
                                                        <option selected>Select Inventory</option>
                                                        <option value="1">In Stock</option>
                                                        <option value="2">Limited</option>
                                                        <option value="3">Out Of Stock</option>
                                                    </select>  
                                                        </th> */}
                                                            <th scope="col">
                                                                {/* <span className="text-muted" >Action</span> */}
                                                                {/* <p className="text-muted"></p>  */}
                                                            </th>
                                                            <th scope="col">
                                                                {/* <span className="text-muted" >Action</span> */}
                                                                {/* <p className="text-muted"></p>  */}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.data.map((item) => (
                                                                <tr key={item.Price} className="white-space-no-wrap">
                                                                    <td className="">
                                                                        <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                            {/* <div className="h-avatar is-medium">
                                                                                <img className="avatar rounded" alt="user-icon" src={item.product} />
                                                                            </div> */}
                                                                            <div className="data-content">
                                                                                <div>
                                                                                    <span className="font-weight-bold">{item.name}</span>
                                                                                </div>
                                                                                {/* <p className="m-0 mt-1">
                                                                                    {item.des}
                                                                                </p> */}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td><a href={item.url}>{item.url}</a></td>
                                                                    <td>
                                                                        {item.state}
                                                                    </td>
                                                                    {/* <td>
                                                                        <p className={`mb-0 ${item.color} font-weight-bold d-flex justify-content-start align-items-center`}>
                                                                            <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                                <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                                                                            </small>{item.Inventory}
                                                                        </p>
                                                                    </td> */}
                                                                    <td>
                                                                        <div className="d-flex justify-content-end align-items-center">
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                                <Link to={"/user-edit?id=" + item.id} className="">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 20 20" fill="currentColor">
                                                                                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                                                    </svg>
                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex justify-content-end align-items-center">
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                                                                <Link to={"/user-view?id=" + item.id} className="">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                                                    </svg>
                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))

                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Product;
