import React, { Component } from 'react'
import { Container, Row, Col, ListGroup } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../../dataProvider'

class Orderdetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        dataProvider.getOne("User", { id }).then((res) => {
            this.setState({
                data: res.data
            })
        })
    }

    render() {
        if (!this.state.data)
            return <></>
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/user">User</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">User Details</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="font-weight-bold">User Details</h4>
                                {/* <Link className="btn btn-primary btn-sm" to="/order-new">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        Generate Invoice
                    </Link> */}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <Card>
                                <ListGroup as="ul" className="list-group-flush">
                                    <ListGroup.Item as="li" className="p-3">
                                        <h5 className="font-weight-bold pb-2">User Info</h5>
                                        <div className="table-responsive">
                                            <table className="table table-borderless mb-0">
                                                <tbody>
                                                    <tr className="white-space-no-wrap">
                                                        <td className="text-muted pl-0">
                                                            Login
                                                        </td>
                                                        <td>
                                                            {this.state.data.login}
                                                        </td>
                                                    </tr>
                                                    <tr className="white-space-no-wrap">
                                                        <td className="text-muted pl-0">
                                                            Email
                                                        </td>
                                                        <td>
                                                            {this.state.data.email}
                                                        </td>
                                                    </tr>
                                                    <tr className="white-space-no-wrap">
                                                        <td className="text-muted pl-0">
                                                            Credits
                                                        </td>
                                                        <td className="text-primary">
                                                            {this.state.data.credits}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Orderdetails;