import React, { Component } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../../dataProvider'


class Productadd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    submit() {
        this.setState({ loading: true }, () => {
            const error = {}
            if (!this.state.title)
                error.title = true
            if (!this.state.target_request)
                error.target_request = true
            if (!this.state.brief)
                error.brief = true
            if (Object.keys(error).length === 0) {
                dataProvider.create('TextVirgin', {
                    data: {
                        title: this.state.title,
                        target_request: this.state.target_request,
                        brief: this.state.brief,
                    }
                }).then(() => window.location = '/texts')
            } else {
                this.setState({ error, loading: false })
            }
        })
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/texts">Texts</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Add Text</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/product" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">New Text</h4>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    {/* <h5 className="font-weight-bold mb-3">Basic Information</h5> */}
                                    <Form className="row g-3">
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Title *</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text1" placeholder="Enter Title" onChange={e => this.setState({ title: e.target.value })} style={this.state.error && this.state.error.title ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Request *</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text1" placeholder="Enter Request" onChange={e => this.setState({ target_request: e.target.value })} style={this.state.error && this.state.error.target_request ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-12 mb-6">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Brief *</Form.Label>
                                            <Form.Control as="textarea" className="form-control" id="Text1" rows="4" placeholder="Enter Brief" onChange={e => this.setState({ brief: e.target.value })} style={this.state.error && this.state.error.brief ? { border: "1px solid red" } : {}} />
                                        </div>
                                        {/* <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="inputState" className="form-label font-weight-bold text-muted text-uppercase">Category</Form.Label>
                                            <select id="inputState" className="form-select form-control choicesjs">
                                                <option value="selected">Select Category</option>
                                                <option value="Electronics">Electronics</option>
                                                <option value="Applications">Applications</option>
                                                <option value="Gadgets">Gadgets</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text2" className="form-label font-weight-bold text-muted text-uppercase">Product Code</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text2" placeholder="Enter Product Code" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text3" className="form-label font-weight-bold text-muted text-uppercase">Product Sku</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text3" placeholder="Enter Product SKU" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text4" className="form-label font-weight-bold text-muted text-uppercase">manufacturer</Form.Label>
                                            <Form.Label type="text" className="form-control" id="Text4" placeholder="Enter Manufacturer" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text5" className="form-label font-weight-bold text-muted text-uppercase">Quantity</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text5" placeholder="Enter Quantity" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text6" className="form-label font-weight-bold text-muted text-uppercase">Price</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text6" placeholder="Enter Price" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text7" className="form-label font-weight-bold text-muted text-uppercase">Tax</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text7" placeholder="Enter Tax" />
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <Form.Label htmlFor="Text8" className="form-label font-weight-bold text-muted text-uppercase">Tag</Form.Label>
                                            <select defaultValue="1" id="Text8" className="multipleSelect2  form-control choicesjs" multiple={false}>
                                                <option value="1">Excellent</option>
                                                <option value="2">Very Good</option>
                                                <option value="3">Good</option>
                                                <option value="4">Not Bad</option>
                                                <option value="5">Bad</option>
                                                <option value="6">Very Bad</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <Form.Label htmlFor="Text9" className="form-label font-weight-bold text-muted text-uppercase">Description</Form.Label>
                                            <Form.Control as="textarea" id="Text9" rows="2" placeholder="Enter Description" />
                                        </div> */}
                                    </Form>
                                </Card.Body>
                                <Card.Body>
                                    <div className="d-flex justify-content-end mt-3">
                                        {
                                            !this.state.loading && (
                                                <Button variant="btn btn-primary" onClick={() => this.submit()}>
                                                    Create Text
                                                </Button>
                                            )
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Productadd;